import React from 'react';
import { Link } from 'react-router-dom';
import { errorUiEndpoint, loginEndpoint } from '../utils/endpoints';
import amazonLogo from '../amazon_logo.svg'
import '../css/App.css';
import '../css/LoginForm.css';

const PASSWORD_LENGTH = 6;

function areCredentialsInvalid(queryParams: string) {
    return queryParams.search("error=invalid-credentials") !== -1;
}

function isSessionExpired(queryParams: string) {
    return queryParams.search("error=session-expired") !== -1;
}

function isDeviceAuthFailureBreached(queryParams: string) {
    return queryParams.search("error=device-auth-failure-breached") !== -1;
}

function isUserLoginFailureBreached(queryParams: string) {
    return queryParams.search("error=user-login-failure-breached") !== -1;
}

class LoginPage extends React.Component {
    state = {
        csrfToken: undefined,
        csrfTokenSet: false,
        password: '',
        maskedPassword: '',
        username: '',
        showInvalidCredentialsBanner: false,
        showInvalidPinBanner: false,
        showSessionExpiredBanner: false,
        showUserLoginFailureBreachedBanner: false,
        showDeviceAuthFailureBreachedBanner: false,
        submitClicked: false,
        sessionExpiration: new Date(),
        secondsToExpiry: 270
    }

    componentDidMount() {
        let invalidCredentials = false;
        let sessionExpired = false;
        let userLoginFailureBreached = false;
        let deviceAuthFailureBreached = false;
        if (areCredentialsInvalid(window.location.search)) {
            invalidCredentials = true;
        }
        if (isSessionExpired(window.location.search)) {
            sessionExpired = true;
        }
        if (isDeviceAuthFailureBreached(window.location.search)) {
            deviceAuthFailureBreached = true;
        }
        if (isUserLoginFailureBreached(window.location.search)) {
            userLoginFailureBreached = true;
        }

        // Using redirect:manual as When following a redirect during a CORS request,
        // if the request is redirected to a URL on a different server, the Origin header is changed to "null".
        fetch(loginEndpoint.concat(window.location.search), {
            credentials: 'include',
            redirect: 'manual'
        }).then((response) => {
            if (response.type === "opaqueredirect" && response.status === 0) {
                // response will be a 302 redirect in case backend sends an error response.
                // We are handling redirect manually so in this case, response.status = 0 and response.type = "opaqueredirect" indicates redirect when `redirect` is 'manual'.
                window.location.href = errorUiEndpoint.concat(window.location.search);
                return null;
            } else {
                return response.json();
            }
        }).then((data) => {
            this.setState({
                csrfToken: data._csrf,
                csrfTokenSet: true,
                showInvalidCredentialsBanner: invalidCredentials,
                showSessionExpiredBanner: sessionExpired,
                showDeviceAuthFailureBreachedBanner: deviceAuthFailureBreached,
                showUserLoginFailureBreachedBanner: userLoginFailureBreached
            });
        }).catch((error) => {
            console.error(error);
            if (window.location.href.includes("localhost")) {
                console.log("localhost testing - not redirecting to error page");
            } else {
                window.location.href = errorUiEndpoint.concat(window.location.search);
            }
        });

        this.setState({ sessionExpiration: new Date(Date.now() + 270 * 1000) }) //270 second session length
        setInterval(() => {
            this.setState({ secondsToExpiry: (Math.floor((this.state.sessionExpiration.getTime() - Date.now()) / 1000)) });
            if (this.state.secondsToExpiry <= 0) {
                window.history.back()
            }
        }, 1000);
    }

    handleUsername = (event: any) => {
        this.setState({
            username: event.target.value.toLowerCase()
        });
    }

    handleCopyPaste = (event: any) => {
        event.preventDefault();
    };

    handleClick = (_: any) => {
        this.setState({
            submitClicked: true
        });
    };

    //method to mask the password with "•", since we are not using the type="password" attribute for the PIN input.
    handleChange = (event: any) => {
        if (event.nativeEvent.data) {
            const char = event.nativeEvent.data;
            const isDigit = !isNaN(parseInt(event.nativeEvent.data));

            if (!isDigit || this.state.maskedPassword.length >= PASSWORD_LENGTH) {
                event.preventDefault();
                return;
            }

            this.setState((prevState: any) => ({
                maskedPassword: prevState.maskedPassword + "•",
                password: prevState.password + char,
                showInvalidPinBanner: prevState.maskedPassword.length + 1 < PASSWORD_LENGTH
            }));
            event.preventDefault();
        } else {
            this.setState((prevState: any) => ({
                maskedPassword: prevState.maskedPassword.slice(0, prevState.maskedPassword.length - 1),
                password: prevState.password.slice(0, prevState.password.length - 1),
                showInvalidPinBanner: prevState.maskedPassword.length - 1 > 0
            }));
        }
    }

    render() {
        let invalidCredentialsBanner = <></>;
        let invalidPinBanner = <></>;
        let sessionExpiredBanner = <></>;
        let deviceAuthFailureBreachedBanner = <></>;
        let userLoginFailureBreachedBanner = <></>;
        if (this.state.showDeviceAuthFailureBreachedBanner) {
            deviceAuthFailureBreachedBanner = <p className="error-message">This device has been blocked due to multiple failed attempts.</p>;
        } else if (this.state.showUserLoginFailureBreachedBanner) {
            userLoginFailureBreachedBanner = <p className="error-message">Username is blocked for now!</p>;
        } else if (this.state.showInvalidCredentialsBanner) {
            invalidCredentialsBanner = <p className="error-message">Invalid credentials entered. Please retry.</p>;
        } else if (this.state.showInvalidPinBanner) {
            invalidPinBanner = <p className="error-message">PIN length must be {PASSWORD_LENGTH}.</p>;
        } else if (this.state.showSessionExpiredBanner) {
            sessionExpiredBanner = <p className="error-message">The Session has expired. Please retry.</p>;
        }

        return (
            <form method="POST" onSubmit={this.handleClick} action={`${loginEndpoint}${window.location.search}`} className="IhmLoginForm">
                <img className="amazon-logo" src={amazonLogo} alt="Amazon Logo" />
                {deviceAuthFailureBreachedBanner}
                {userLoginFailureBreachedBanner}
                {invalidCredentialsBanner}
                {invalidPinBanner}
                {sessionExpiredBanner}
                <h3>Enter your user ID and PIN to log in</h3>
                <input name="_csrf" type="hidden" defaultValue={this.state.csrfToken} />
                <input className="form-group form-control" type="text" name="username" autoCapitalize='off' value={this.state.username} onChange={(event) => this.handleUsername(event)}
                    placeholder="User Name" required autoFocus />
                {/*input field to store masked Password that is displayed to the user, name attribute is not used as we don't want to send the masked password in form submission*/}
                <input className="form-group form-control" type="tel" id="maskedPassword" maxLength={PASSWORD_LENGTH} autoComplete="off" value={this.state.maskedPassword} onChange={this.handleChange}
                    onPaste={this.handleCopyPaste} onCopy={this.handleCopyPaste} placeholder="PIN" required />
                {/*input field to store actual password that is used for form submission */}
                <input type="hidden" name="password" value={this.state.password} />
                {/* The disabled attribute of the submit button is set to true if either the username or password state variables are empty or csrf token is not set.*/}
                <button type="submit" className="form-group btn-yellow" disabled={!(this.state.username && this.state.password.length === PASSWORD_LENGTH && this.state.csrfTokenSet && !this.state.submitClicked)}>Login</button>
                <span> New user? Forgot PIN? </span>
                <Link to={`/signup${window.location.search ? window.location.search : '?'}&timeout=${this.state.secondsToExpiry}`}>
                    <button type="submit" className="form-group btn-grey">Set PIN</button>
                </Link>
                <div>
                    Session Expires In {this.state.secondsToExpiry >= 0 ? this.state.secondsToExpiry : "Session Expired! Redirecting..."}
                </div>
            </form>
        )
    };
}

export default LoginPage;
