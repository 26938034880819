import { useState, useEffect } from 'react';
import SetPinModule from './SetPinModule';
import amazonLogo from '../amazon_logo.svg'
import { errorUiEndpoint, signupEndpoint, getOtpEndpoint, verifyOtpEndpoint } from '../utils/endpoints';
import '../css/App.css';
import '../css/LoginForm.css';

// OTP is actually valid for 15 minutes
const OTP_TIMEOUT_IN_SEC = 30;

const SignupPage = () => {
    const [username, setUsername] = useState('');
    const [otp, setOtp] = useState('');
    const [maskedOtp, setMaskedOtp] = useState('');
    const [showResendOtp, setShowResendOtp] = useState(false);
    const [resendOtpCountDown, setResendOtpCountDown] = useState(OTP_TIMEOUT_IN_SEC);
    const [challengeId, setChallengeId] = useState('');
    const [csrfToken, setCsrfToken] = useState('');
    const [csrfTokenSet, setCsrfTokenSet] = useState(false);
    const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [otpSuccessMessage, setOtpSuccessMessage] = useState('');
    const [requestOTPClicked, setRequestOTPClicked] = useState(false);
    const [verifyOTPClicked, setVerifyOTPClicked] = useState(false);
    const [submitPinClicked, setSubmitPinClicked] = useState(false);
    const [sessionExpiration, setSessionExpiration] = useState(new Date());
    const [secondsToExpiry, setSecondsToExpiry] = useState(270);

    // Using redirect:manual as When following a redirect during a CORS request,
    // if the request is redirected to a URL on a different server, the Origin header is changed to "null".
    useEffect(() => {
        if (isSignupError(window.location.search)) {
            setErrorMessage("Error occurred while user signup.");
        }
        fetch(signupEndpoint.concat(window.location.search), {
            credentials: 'include',
            redirect: 'manual'
        }).then((response) => {
            if (response.type === "opaqueredirect" && response.status === 0) {
                // response will be a 302 redirect in case backend sends an error response.
                // We are handling redirect manually so in this case, response.status = 0 and response.type = "opaqueredirect" indicates redirect when `redirect` is 'manual'.
                window.location.href = errorUiEndpoint.concat(window.location.search);
                return null;
            } else {
                return response.json();
            }
        }).then((data) => {
            setCsrfToken(data._csrf);
            setCsrfTokenSet(true);
        }).catch((error) => {
            console.error(error);
            if (window.location.href.includes("localhost")) {
                console.log("localhost testing - not redirecting to error page");
            } else {
                window.location.href = errorUiEndpoint.concat(window.location.search);
            }
        });
        let params = new URLSearchParams(window.location.search);
        let timeout = params.get('timeout');
        if (timeout) {
            setSessionExpiration(new Date(Date.now() + parseInt(timeout) * 1000));
            setSecondsToExpiry(parseInt(timeout));
        }
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setSecondsToExpiry(Math.floor((sessionExpiration.getTime() - Date.now()) / 1000));
        }, 1000);
        if (secondsToExpiry <= 0) {
            window.history.go(-2);
        }
        return () => clearInterval(timer);
    }, [secondsToExpiry, sessionExpiration]);

    useEffect(() => {
        if (!isVerifyingOtp) {
            return;
        }
        // Handle timeout for the opt verification
        if (resendOtpCountDown > 0) {
            const timeoutId = setTimeout(() => {
                setResendOtpCountDown(resendOtpCountDown - 1);
            }, 1000);
            return () => clearTimeout(timeoutId);
        } else {
            setOtp('');
            setMaskedOtp('');
            setShowResendOtp(true);
        }
    }, [isVerifyingOtp, resendOtpCountDown]);

    const onClickRequestOtp = () => {
        setRequestOTPClicked(true);
        setVerifyOTPClicked(false);
        const regex = /^[a-z]{3,12}$/;
        if (regex.test(username)) {
            setErrorMessage('');
            setOtpSuccessMessage('');
            handleGetOtp();
        } else {
            setErrorMessage('Please enter valid username');
            setRequestOTPClicked(false);
            setOtpSuccessMessage('');
        }
    }

    const handleGetOtp = async () => {
        try {
            const response = await fetch(getOtpEndpoint.concat(`?username=${username}`), {
                credentials: 'include',
                redirect: 'manual'
            });
            const data = await response.json();
            if (response.status === 200) {
                if (showResendOtp) {
                    setShowResendOtp(false);
                }
                setResendOtpCountDown(OTP_TIMEOUT_IN_SEC);
                setChallengeId(data.challengeId);
                setIsVerifyingOtp(true);
                setOtpSuccessMessage(`OTP sent to phone ${data.obfuscatedPhoneNumber}`);
                setErrorMessage('');
            } else {
                setRequestOTPClicked(false);
                setErrorMessage(data.errorMessage);
                setOtpSuccessMessage('');
            }
        } catch (error) {
            setRequestOTPClicked(false);
            setErrorMessage('Internal Service Error');
            setOtpSuccessMessage('');
            console.error(error);
        }
    };

    const handleVerifyOtp = async () => {
        try {
            setVerifyOTPClicked(true);
            const verifyOtpData = `otp=${otp}&challengeId=${challengeId}&username=${username}`;
            const response = await fetch(verifyOtpEndpoint, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': csrfToken,
                },
                body: verifyOtpData,
            });
            const data = await response.json();
            if (response.status === 200) {
                if (data.isOtpVerified) {
                    setIsOtpVerified(true);
                    setIsVerifyingOtp(false);
                    setErrorMessage('');
                    setOtpSuccessMessage('');
                } else {
                    setVerifyOTPClicked(false)
                    setOtpSuccessMessage('');
                    setErrorMessage('Incorrect OTP entered.');
                    setOtp('');
                    setMaskedOtp('');
                }
            } else {
                setVerifyOTPClicked(false)
                setOtpSuccessMessage('');
                setErrorMessage(data.errorMessage);
                setOtp('');
                setMaskedOtp('');
            }
        } catch (error) {
            setVerifyOTPClicked(false);
            setErrorMessage("Internal Service Error");
            setOtpSuccessMessage('');
            console.error(error);
        }
    };

    const isSignupError = (queryParams: string) => {
        return queryParams.search("error=signup-error") !== -1;
    }

    // Prevent enter key submitting the form
    const handleUsernameKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    // Method to mask the password with "•", since we are not using the type="password" attribute for the PIN input.
    const handleChange = (event: any) => {
        if (event.nativeEvent.data) {
            const char = event.nativeEvent.data;
            const isDigit = !isNaN(parseInt(event.nativeEvent.data));

            if (!isDigit || otp.length >= 6) {
                event.preventDefault();
                return;
            }

            setMaskedOtp(maskedOtp + "•");
            setOtp(otp + char);
        } else {
            setMaskedOtp(maskedOtp.slice(0, maskedOtp.length - 1));
            setOtp(otp.slice(0, otp.length - 1));
        }
    }

    const handleSubmit = (_: any) => {
        setSubmitPinClicked(true);
    };

    const alternativeSignupMethodMessage = (<p className="SignupInfoMessage">
        Alternatively, you can request your <b>store manager </b> to reset your PIN (or) create your account.</p>);

    return (
        <form method="POST" onSubmit={handleSubmit} action={`${signupEndpoint}${window.location.search}`} className="IhmSignupForm">
            <img className="amazon-logo" src={amazonLogo} alt="Amazon Logo" />
            <input name="_csrf" type="hidden" defaultValue={csrfToken} />
            <input type="hidden" name="requiredAttributes[email]" value={`${username}@amazon.com`} readOnly />
            <input type="hidden" name="username" value={username} />
            {window.location.href.includes('prod.ap-southeast-1.ui') && 
                <p style={{fontSize: "90%"}}>OTP not yet supported</p>
            }
            {errorMessage &&
                <p className="error-message">{errorMessage}</p>
            }
            {otpSuccessMessage &&
                <p className="success-message">{otpSuccessMessage}</p>
            }
            {!isOtpVerified && (
                <>
                    <input className="form-group form-control" type="text" name="username" placeholder="Enter username"
                        autoCapitalize='off' value={username} onChange={(event) => setUsername(event.target.value.toLowerCase())} disabled={(isVerifyingOtp && !showResendOtp)}
                        onKeyDown={handleUsernameKeyDown} required />
                    {!isVerifyingOtp && (
                        <>
                            <button type="button" className="form-group btn-yellow" onClick={onClickRequestOtp} disabled={!(!requestOTPClicked && csrfTokenSet)}>Request OTP</button>
                            {alternativeSignupMethodMessage}
                        </>
                    )}
                </>
            )}
            {isVerifyingOtp && (
                <>
                    <input className="form-group form-control" type="tel" id="otp" maxLength={6} placeholder="Enter OTP"
                        value={maskedOtp} onChange={handleChange} required autoFocus />
                    <button type="button" className="form-group btn-yellow" onClick={handleVerifyOtp}
                        disabled={otp.length !== 6 || verifyOTPClicked}>Verify OTP</button>
                    {showResendOtp ?
                        (<button type="button" className="form-group btn-yellow" onClick={onClickRequestOtp}>Resend OTP</button>) :
                        (<p style={{ color: "red", fontWeight: "bold" }}> Please wait {resendOtpCountDown}s before generating a new OTP</p>)
                    }
                    {alternativeSignupMethodMessage}
                </>
            )}
            {isOtpVerified && <SetPinModule pinClicked={submitPinClicked} />}
            <div>
                Session Expires In {secondsToExpiry >= 0 ? secondsToExpiry : "Session Expired! Redirecting..."}
            </div>
        </form>
    );
};

export default SignupPage;
